body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

html {
  scroll-behavior: smooth;
}

.dropShadow {
  filter: drop-shadow(0 0 0.75rem gray);
}

dialog {
  position: absolute;
  left: 0; right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: block;
}

dialog:not([open]) {
  display: none;
}

dialog + .backdrop {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  background: rgba(0,0,0,0.1);
}

._dialog_overlay {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
}

dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    transform: none;
  }
}

dialog {
  padding: 45px 50px 50px;
  border-radius: 8px;
  border: none;
  box-shadow: 0 15px 50px rgba(0, 0, 0, .2);
  transform: translateY(50vh) scale(0, 1.2);
  will-change: transform, opacity;
}

::backdrop {
  background: radial-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .8));
}

[open],
::backdrop {
  animation: show .7s cubic-bezier(.2, 1, .2, 1.04) forwards;
}
